import React from "react";

import { Link } from "gatsby";
import Wrap from "../components/wrap";
import SEO from "../components/seo";

function NotFoundPage() {
  return (
    <>
      <SEO title="404: Not found" />
      <Wrap>
        <h1 className="c-h3 text-red-600">404 Error page</h1>
        <h2 className="c-h2 text-red-800">
          This page was lost in the jungle 🌴
        </h2>
        <p className="c-p">It's probably best if you return back to safety.</p>
        <Link className="c-button" to="/">
          Back home
        </Link>
      </Wrap>
    </>
  );
}

export default NotFoundPage;
